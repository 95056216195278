// export const backend_url = "http://localhost:3050";
// export const backend_url = "https://api.payserve.co.ke";
export const backend_url = "https://api.sandbox.payserve.co.ke";

export const checkEmailAndPasswordURL = "/api/auth/check_email_and_password";
export const loginURL = "/api/auth/login";
export const forgotPasswordURL = "/api/auth/forgot_password";
export const resetPasswordURL = "/api/auth/reset_password";
export const codeVerificationURL = "/api/auth/otp";
export const resendCodeURL = "/api/auth/resend_code";
export const verifyOTPURL = "/api/auth/verify_otp";
export const getDashboardDataURL =
  "/api/core/dashboard_management/get_dashboard_data";
export const checkTokenExpirationURL = "/api/auth/check_jwt_expiration";
export const checkAuthorizationURL = "/api/auth/check_authorization";
export const getSMSEmailSettingsURL =
  "/api/core/settings/get_sms_email_settings";
export const fetchUsersURL = "/api/core/settings/get_users";
export const addNewUserURL = "/api/core/settings/add_new_user";
export const deleteUserURL = "/api/core/settings/delete_user";
export const updateSMSEmailSettingsURL =
  "/api/core/settings/update_sms_email_settings";
export const addWaterConcentratorURL = "/api/core/water/add_concentrator";
export const getWaterConcentratorsURL = "/api/core/water/get_concentrators";
export const importWaterConcentratorsURL =
  "/api/core/water/import_concentrators";
// export const addWaterMeterURL = '/api/core/water/add_water_meter'
export const addWaterMeterURL = "/api/core/water_management/meters/add_meter";
export const getWaterMetersURL = "/api/core/water/get_water_meters";
export const importWaterMetersURL = "/api/core/water/import_water_meters";
export const confirmCompanyNameURL =
  "/api/core/company_management/confirm_company_name";
export const getCompaniesURL = "/api/core/company_management/get_companies";
export const getCompanyDetails = "/api/core/company_management/get_company";
export const addCompanyURL = "/api/core/company_management/add_company";
export const addExistingCompanyToUserURL =
  "/api/core/user_management/add_a_existing_company_to_user";
export const addNewCompanyToUserURL =
  "/api/core/user_management/add_a_new_company_to_user";
export const getCompanyUsersURL = "/api/core/user_management/get_company_users";
export const confirmUserEmailURL =
  "/api/core/user_management/confirm_user_email";
export const EnableCompany = "/api/core/company_management/enable_company";
export const DisableCompany = "/api/core/company_management/disable_company";
export const getSitesURL = "/api/core/site_management/get_sites";
export const addSiteURL = "/api/core/site_management/add_site";
export const getProjectsURL = "/api/core/project_management/get_projects";
export const getFacilitiesURL = "/api/core/facility_management/get_facilities";
export const deleteDivisionFacilitiesURL =
  "/api/core/facility_management/delete_division_facilities_url";
export const addDivisionFacilitiesURL =
  "/api/core/facility_management/add_division_facilities_url";
export const addFacilityURL = "/api/core/facility_management/add_facility";
export const getFacilityURL = "/api/core/facility_management/get_facility";
export const getFacilityUnits =
  "/api/core/unit_management/get_units_per_facility";
export const updateFacilityMeasurement =
  "/api/core/facility_management/update_facility_measurement";
export const updateTotalCommonArea =
  "/api/core/facility_management/update_total_common_area";
export const updateLettableArea =
  "/api/core/facility_management/update_lettable_area";
export const updateFacilityInfo =
  "/api/core/facility_management/update_facility_info";
export const updateFacilityModules =
  "/api/core/facility_management/update_facility_modules";
export const addAsset = "/api/core/facility_management/add_asset";
export const deleteAsset = "/api/core/facility_management/delete_asset";
export const addLRNumber = "/api/core/facility_management/add_lr_number";
export const deleteLRNumber = "/api/core/facility_management/delete_lr_number";
export const addUnitUrl = "/api/core/unit_management/add_unit";
export const importUnitsURL = "/api/core/unit_management/import_units";
export const getUnitUrl = "/api/core/unit_management/get_unit";
export const addCombinedUnitURL = "/api/core/unit_management/add_combine_units";
export const getFacilityCombinedUnits =
  "/api/core/unit_management/get_combine_units";
export const deleteFacilityCombineUnits =
  "/api/core/unit_management/delete_combine_unit";
export const confirmCombinedUnitsURL =
  "/api/core/unit_management/confirm_combined_units";
export const get_units_per_facility =
  "/api/core/unit_management/get_units_per_facility";
export const addUnitAsset = "/api/core/unit_management/add_new_unit_asset";
export const getUnitAsset = "/api/core/unit_management/get_unit_assets";
export const updateUnitName = "/api/core/unit_management/update_unit_name";
export const deleteUnitAsset = "/api/core/unit_management/delete_unit_asset";
export const updateUnit = "/api/core/unit_management/update_unit";
export const EnableUnit = "/api/core/unit_management/enable_unit";
export const DisableUnit = "/api/core/unit_management/disable_unit";
export const releaseUnitURL = "/api/core/unit_management/release_unit";
export const releaseUnitFieldURL = "/api/core/unit_management/release_unit_field";
export const getMessagesURL = "/api/core/messages/get_messages";
export const getTransactionsURL = "";
export const getWaterMeterAccountURL = "";
export const getAPIMetricsURL = "/api/core/api_management/get_metrics";
export const addWaterMeterSettings =
  "/api/core/water_management/meters/add_meter_settings";
export const getWaterMeterSettings =
  "/api/core/water_management/meters/get_meter_settings";
export const addWaterMeterSize =
  "/api/core/water_management/meters/add_meter_size";

export const concentratorBaseUrl = "/api/core/concentrators";
export const waterMeterBaseUrl = "/api/core/water-meters";
export const waterMeterSizeBaseUrl = "/api/core/water-meters-sizes";
export const waterMeterProtocolsBaseUrl =
  "/api/core/water-meter-communcation-protocol";
export const waterMeterManufacturerBaseUrl =
  "/api/core/water-meter-manufacturer";
export const waterMeterIotCardsBaseBaseUrl = "/api/core/water-meter-iot-cards";
export const settingsBaseRoute = "/api/core/settings";
export const paymentBaseRoute = "/api/core/payment_details";
export const facilityPaymentBaseRoute = "/api/core/facility_payment_details";
